import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/image-carousel"
import "../components/layout.css"
import { Button } from "semantic-ui-react"
import { connect } from "react-redux"

class IndexPage extends React.Component {
  render() {
    const { lang } = this.props
    return (
      <Layout>
        <SEO title="Cell phones, wireless tech" />
        <div
          style={{
            height: "auto",
            margin: "auto",
            marginTop: "6rem",
          }}
        >
          <Carousel />
        </div>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
  }),
  null
)(IndexPage)
